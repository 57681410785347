
export const SYSTEM_SETTINGS_LOADED = 'SYSTEM_SETTINGS_LOADED'

export const USER_LOADED = 'USER_LOADED'
export const USER_ERROR = 'USER_ERROR'

export const COMMUNITIES_DEFAULT_ID = 'COMMUNITIES_DEFAULT_ID'

export const COMMUNITIES_DATA_LOADED = 'COMMUNITIES_DATA_LOADED'

export const COMMUNITY_INFO_LOADED = 'COMMUNITY_INFO_LOADED'

export const COMMUNITY_EVENTS_LOADED = 'COMMUNITY_EVENTS_LOADED'

export const COMMUNITY_NEWS_LOADED = 'COMMUNITY_NEWS_LOADED'

export const COMMUNITY_POLLS_LOADED = 'COMMUNITY_POLLS_LOADED'

export const COMMUNITY_FORMS_LOADED = 'COMMUNITY_FORMS_LOADED'

export const DISPLAY_SYSTEM_MESSAGE = 'DISPLAY_SYSTEM_MESSAGE'

export const createAction = (type, payload) => {
    return { type, payload }
}
