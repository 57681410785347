export function isEmail(text) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
}

export function isAlphaNumeric(text) {
    const re = /^[a-zA-Z0-9-_@.]+$/
    return re.test(String(text));
}

export function isEmpty(text) {
    if (!text) return true;

    return text.toString().split(' ').join('') === ''
}

export function getTextFullLength(text) {
    if (!text) return 0;
    return text.split(' ').join('').length;
}

export function trimSpaces(text) {
    return text.split(' ').join('');
}

export function isUrl(text) {
    const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g
    return re.test(String(text).toLowerCase());
}

export function isFormValid(obj) {
    if (!obj) return false
    let result = true
    Object.values(obj).forEach(item => {
        if (item === false) result = false;
    })
    return result;
}

export function validate(text, type='text', options ) {

    const {mandatory, min, max} = options

    if (!mandatory && text.length === 0) return '';

    if (mandatory && isEmpty(text)) return 'emptyText'
    if (type === 'email' && !isEmail(text)) return 'emailText';
    if (type === 'url' && !isUrl(text)) return 'urlText';
    if (min && trimSpaces(text).length < min) return 'minText';
    if (max && trimSpaces(text).length > max) return 'maxText';

    return '';
}
