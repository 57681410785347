import APIService from "../services/api-service";
import { ORGANIZATIONS_LOADED, ORGANIZATIONS_ERROR, ORGANIZATIONS_FILTER } from "../constants/actions";
import { createAction } from "../utils/actions-factory";

const apiService = new APIService();

export const getOrganizations = (dispatch) => (languageToLoad='') => {

    let language = languageToLoad
    if (process.env.REACT_APP_PASS_LANGUAGE && !language) {
        language = process.env.REACT_APP_LANGUAGE_MAIN
    }

    apiService.getOrganizations(language)
        .then((data) => dispatch(createAction(ORGANIZATIONS_LOADED, data)))
        .catch((err) => dispatch(createAction(ORGANIZATIONS_ERROR, err)));
}

export const filterOrganizations = (dispatch) => (country='', search='', categoriesFilter=[]) => {
    dispatch(createAction(ORGANIZATIONS_FILTER, { country, search, categoriesFilter }))
}
