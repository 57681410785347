import React from "react";
import {connect} from "react-redux";
import SearchPreviewCard from "./search-preview-card";
import { geObjectToObjectDistance } from "../../utils/helpers";
import APIService from "../../services/api-service";

const apiService = new APIService()

const SearchPreview = props => {

    const { filtered, onClick, top, organizations, browserGeo } = props;

    let fuaturedInitial = []

    if (filtered.length === organizations.length) return null

    if (!filtered.length) {
        if (!browserGeo?.lat) {
            return null
        } else {
            const featuredInitialArray = organizations.filter(item => item.featured)
            if (featuredInitialArray.length) {
                featuredInitialArray.forEach(item => {
                    if (item.Lat && item.Lng && browserGeo.lat && browserGeo.lng) {
                        const distance = geObjectToObjectDistance(item.Lat, item.Lng, browserGeo.lat, browserGeo.lng)
                        if (distance && !isNaN(distance) && distance < 50000) {
                            item.distance = distance
                            fuaturedInitial.push(item)
                        }
                    }
                })
            }
        }
    }

    if (fuaturedInitial.length) {
        for (let fItem of fuaturedInitial) {
            if (fItem.id && fItem.Name) {
                apiService.recordLogAction(fItem.id, 'search-map-item', fItem.Name)
            }
        }
    }

    const featured = filtered.filter(item => item.featured)
    const notFeatured = filtered.filter(item => !item.featured)

    let combined = fuaturedInitial?.length ? fuaturedInitial : [...featured, ...notFeatured]

    let filteredLimit = combined.length <=20 ? combined : combined.slice(0, 20)

    return (
        <div>
            <div className='search-preview-container' style={{top: top}}>
                <div className='search-preview-content'>
                    {filteredLimit.map((item, index) => <SearchPreviewCard key={index} item={item} onClick={onClick} />)}
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = ({ data: { filtered, organizations }}) => {
    return { filtered, organizations };
};


export default connect(mapStateToProps)(SearchPreview);

