import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import facebookIco from '../../assets/icons/facebook-logo.svg'
import instagramIco from '../../assets/icons/instagram-logo.svg'
import telegramIco from '../../assets/icons/telegram-logo.svg'
import Linkify from "react-linkify";

const formMain = process.env.REACT_APP_FORM_MAIN
const formAlt = process.env.REACT_APP_FORM_ALT;
const formEn = process.env.REACT_APP_FORM_ALT;
const isCulture = process.env.REACT_APP_THEME === 'culture';

const formToOpen = {
    ru: formAlt,
    by: formMain,
    ua: formMain,
    en: formEn
}


const About = props => {

    const { language, wording } = props;


    return (
        <div className='about-container mt-75'>
            <h2>{wording['heading'][language]}</h2>
            <div className='mt-30'>
                <p className='strong'>{wording['subheading1'][language]}</p>
                <p>{wording['text1'][language]}</p>
            </div>
            <div className='mt-30'>
                <p className='strong'>{wording['subheading2'][language]}</p>
                <p>{wording['text2'][language]}</p>
            </div>
            {!isCulture && <div className='mt-30'>
                <p className='strong'>{wording['subheading3'][language]}</p>
                <p>{wording['text3'][language]}</p>
                {!isCulture && <div className='mt-20'>
                    <a href={formToOpen[language]} target='_blank' rel='noopener noreferrer'><p
                        className='button-link mr-30'>{wording['CTA'][language]}</p></a>
                </div>}
            </div>}
            <div className='mt-30'>
                <p className='strong'>{wording['subheading4'][language]}</p>
                <Linkify><p>{wording['text4'][language]}</p></Linkify>
            </div>
            <div className='separator mt-50 mb-20'/>
            {!isCulture && <div>
                <Link to='/account'>
                    <button className='button-clean'>{wording['account-button'][language]}</button>
                </Link>
            </div>}
            <div className='mt-30'>
                <p className='strong'>{wording['contact'][language]}</p>

                <div className='mt-20'>
                    <a href={`mailto:${wording?.['about-email']?.[language] || 'info@bymapka.me'}`} target='_blank'
                       rel='noopener noreferrer'><p
                        className='button-link mr-30'>{wording?.['about-email']?.[language] || 'info@bymapka.me'}</p>
                    </a>
                </div>
            </div>
            <div className='mt-30'>
                <p className='strong'>{wording['social-media'][language]}</p>

                <div className='d-flex-ac mt-20' style={{gap: 20}}>
                    <a href={`${wording?.['social-fb']?.[language] || 'https://www.facebook.com/ByMapka'}`}
                       target='_blank' rel='noopener noreferrer'>
                        <img src={facebookIco} width={36} height={36} alt={'fb'}/>
                    </a>
                    <a href={`${wording?.['social-instagram']?.[language] || 'https://instagram.com/bymapka?igshid=MzRlODBiNWFlZA=='}`}
                       target='_blank' rel='noopener noreferrer'>
                        <img src={instagramIco} width={36} height={36} alt={'instagram'}/>
                    </a>
                    <a href={`${wording?.['social-telegram']?.[language] || 'https://t.me/bymapka'}`} target='_blank'
                       rel='noopener noreferrer'>
                        <img src={telegramIco} width={36} height={36} alt={'telegram'}/>
                    </a>
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = ({language: {language, wording}}) => {
    return {language, wording};
};

export default connect(mapStateToProps)(About);

