import React from "react";

const HBox = ({ gap, width, align, children, className, sb }) => {

    return (
        <div className={className + ' d-flex-wrap'} style={{gap: gap || 20, width: width || 'fit-content', alignItems: align || 'center', justifyContent: sb ? 'space-between' : 'flex-start'}}>
            { children }
        </div>
    )
}

export default HBox;
