import {
    USER_ERROR,
    USER_LOADED,
} from "../actions/actions-factory";

const updateUser = (state, action) => {

    if (state === undefined) {
        return {
            token: '',
        };
    }

    switch (action.type) {
        case USER_LOADED: {
            return {
                ...state.user,
                token: action.payload.token,
            };
        }

        case USER_ERROR: {
            return {
                ...state.user,
                token: '',
            };
        }

        default:
            return state.user;
    }
};

export default updateUser;
