import React from "react";

const VBox = ({ gap, width, children, className, full }) => {

    return (
        <div className={(className || '') + ' d-flex-col'} style={{gap: gap || 20, width: full ? '100%' : width || 'fit-content'}}>
            { children }
        </div>
    )
}

export default VBox;
