import API from "../services/api-service/api-service";
import {
    createAction,
    USER_ERROR,
    USER_LOADED,
} from "./actions-factory";

const apiService = new API();


export const logoutUser = (dispatch) => async () => {
    dispatch(createAction(USER_ERROR, { token: ""}))
}

export const validatePin = (dispatch) => async (email, pin) => {
    apiService.validateUserPin(email, pin)
        .then(async (data) => {
            dispatch(createAction(USER_LOADED, data))
        })
        .catch(async (err) => {
            dispatch(createAction(USER_ERROR, err));
        });
}
