

const months = {
    ru: ["ЯНВАРЯ", "ФЕВРАЛЯ", "МАРТА", "АПРЕЛЯ", "МАЯ", "ИЮНЯ", "ИЮЛЯ", "АВГУСТА", "СЕНТЯБРЯ", "ОКТЯБРЯ", "НОЯБРЯ", "ДЕКАБРЯ"],
    by: ["СТУДЗЕНЯ", "ЛЮТАГА", "САКАВIКА", "КРАСАВIКА", "МАЯ", "ЧЭРВЕНЯ", "ЛIПЕНЯ", "ЖНIЎНЯ", "ВЕРАСНЯ", "КАСТРЫЧНIКА", "ЛIСТАПАДА", "СНЕЖНЯ"],
    en: ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"],
    ua: ["СІЧНЯ", "ЛЮТОГО", "БЕРЕЗНЯ", "КВІТНЯ", "ТРАВНЯ", "ЧЕРВНЯ", "ЛИПНЯ", "СЕРПНЯ", "ВЕРЕСНЯ", "ЖОВТНЯ", "ЛИСТОПАДА", "ГРУДНЯ"]
}

const days = {
   ru: ["ПОНЕДЕЛЬНИК", "ВТОРНИК", "СРЕДА", "ЧЕТВЕРГ", "ПЯТНИЦА", "СУББОТА", "ВОСКРЕСЕНЬЕ"],
   en: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
   ua: ["ПОНЕДІЛОК", "ВІВТОРОК", "СЕРЕДА", "ЧЕТВЕР", "П'ЯТНИЦЯ", "СУБОТА", "НЕДІЛЯ"],
   by: ["ПАНЯДЗЕЛАК", "АЎТОРАК", "СЕРАДА", "ЧАЦВЕР", "ПЯТНІЦА", "СУБОТА", "НЯДЗЕЛЯ"],
}


export const toTwoDigits = (num) => num < 10 ? "0" + num.toString() : num.toString()

export const getTextDate = (dateToExtract, short=false, small=false, lng='ru') => {
    const date = new Date(dateToExtract)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    let monthLabel = short ? months[lng][date.getMonth()].substring(0, 3) : months[lng][date.getMonth()];
    if (small) monthLabel = monthLabel.toLowerCase();
    return `${date.getDate()} ${monthLabel}`
}

export function timeSince(dateToApply) {

    const date = new Date(dateToApply)

    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " г";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " мес";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " д";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " г";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " м";
    }
    return Math.floor(seconds) + " с";
}

export const getTextDateObject = (dateToExtract, short=true, small=true, lng='ru') => {
    const dateArray = getTextDate(dateToExtract, short, small, lng).split(' ');
    const date = new Date(dateToExtract);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    let dayOfWeek = days[lng][date.getDay()];
    if (short) dayOfWeek = dayOfWeek.substring(0, 3).toLowerCase()

    return {
        date: dateArray[0],
        month: dateArray[1],
        year: date.getFullYear(),
        day: dayOfWeek
    }
}

export const getTextTime = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}`
}

export const dateToStringDate = (data, isSmall= true, isShort=false) => {
    const date = new Date(data);
    if (isNaN(date)) return ''
    return getTextDate(date, isShort, isSmall)
}

export const dateToNumberDate = (data, time) => {
    const date = new Date(data);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (isNaN(date)) return ''

    return `${toTwoDigits(date.getDate())}.${toTwoDigits(date.getMonth()+1)}${time ? (', ' + time) : ''}`
}

export const dateToFullStringDate = (data, isSmall= true, isShort=false, lng='ru') => {
    const date = new Date(data);
    if (isNaN(date)) return ''
    const year = date.getFullYear().toString().substring(2, 4)
    return `${getTextDate(date, isShort, isSmall, lng)}, ${year}`
}

export const dateToStringDateTime = (data, isSmall= true, isShort=false) => {
    const date = new Date(data);
    return `${getTextDate(date, isShort, isSmall)}, ${getTextTime(date)}`
}

export const getTextDateTime = (dateToExtract, short= false, small=false, lng='ru') => {
    const date = new Date(dateToExtract);
    return `${getTextDate(date, short, small, lng)}, ${getTextTime(date)}`
}

export const getTextDateNums = (dateToExtract) => {
    const date = new Date(dateToExtract)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    return `${date.getDate()}.${toTwoDigits(date.getMonth() + 1)}`
}

export const toBrowserDate = (dateToExtract) => {
    const date = new Date(dateToExtract)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    return `${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}`;
}

export const isToday = (dateToExtract) => {
    const date = new Date(dateToExtract)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    const today = new Date();
    return (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
}

