import React, { useState } from "react";
import { isEmail, isEmpty } from "../../utils/validators";
import API from "../../services/api-service/api-service"
import VBox from "../ui/layouts/vbox";
import HBox from "../ui/layouts/hbox";
import { validatePin } from "../../actions/actions-user";
import {connect} from "react-redux";

const apiService = new API()

const LoginScreenEmail = props =>  {

    const { validatePin, language, wording } = props;

    const [page, setPage] = useState(0)
    const [email, setEmail] = useState('')
    const [pin, setPin] = useState('')

    const loginHandler = async () => {

        if (email && isEmail(email.trim())) {
            try {
                const result = await apiService.loginUserByEmail(email.trim())
                if (result) {
                    setPage(1)
                } else {
                    // error
                }
            } catch (e) {
                // error
            }
        } else {
            // error
        }
    }

    /*
    const codeBackupHandler = async () => {
        if (email && isEmail(email.trim())) {
            try {
                const result = await apiService.loginUserByEmail(email.trim(), true)
                if (result) {
                    setPage(1)
                    window.alert('email sent from gmail box')
                    // success
                } else {
                    // error
                }
            } catch (e) {
                // error
            }
        } else {
            // error
        }
    }*/

    const validateHandler = () => {
        if (email && isEmail(email.trim()) && pin && !isEmpty(pin) && pin.trim().length <= 10) {

            validatePin(email.trim(), pin.trim())

        } else {
            // error
        }
    }

    const onKeyDownCode = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            await loginHandler()
        }
    }

    const onKeyDownValidate = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            validateHandler()
        }
    }

    return (
        <div>
            <div>
                {page === 0 && <VBox gap={20} width={'100%'}>
                    <p style={{lineHeight: 1.2, fontSize: 12}}>{wording['login-email-heading'][language]}</p>
                    <HBox gap={10}>
                        <input
                            onKeyDown={onKeyDownCode}
                            placeholder={'user@gmail.com'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <div>
                            <button disabled={!isEmail(email.trim())} onClick={() => loginHandler()}>{wording['login-email-send-code'][language]}</button>
                        </div>
                    </HBox>
                </VBox>}
                {page === 1 && <VBox gap={20} width={'100%'}>
                    <p style={{lineHeight: 1.2, fontSize: 12}}>{wording['login-email-pin-heading'][language]}</p>
                    <HBox>
                        <input
                            type='password'
                            placeholder={'XXXXXX'}
                            value={pin}
                            onKeyDown={onKeyDownValidate}
                            onChange={(e) => setPin(e.target.value)}
                        />

                        <div>
                            <button disabled={isEmpty(pin.trim())}  onClick={() => validateHandler()}>{wording['login-email-confirm-code'][language]}</button>
                        </div>
                    </HBox>
                </VBox>}
            </div>
        </div>
    );
}

const mapStateToProps = ({ language: { language, wording }, user: { token }}) => {
    return { language, wording, token };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validatePin: validatePin(dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreenEmail);
