import store from "../store";

const SERVER_URL = process.env.REACT_APP_API_URL
const SERVER_AUTH_URL = process.env.REACT_APP_AUTH_API_URL
const CONNECTION_ERROR_MESSAGE = 'Произошла ошибка при запросе данных';

const getToken = () => {
    return store.getState().user.token
}


export const getAsyncFetch = async (urlPath, method='GET', params=null, token, isAuth) => {
    try {
        const url = (isAuth ? SERVER_AUTH_URL : SERVER_URL) + urlPath;
        const request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            ...(params && {body: JSON.stringify(params)})
        }

        if (token) {
            request.headers['Authorization'] = `Bearer ${ getToken() }`;
        }

        const response = await fetch(url, request);
        if (!response.ok) {
            console.log(`An error has occured: ${response.status}`)
            return Promise.reject(CONNECTION_ERROR_MESSAGE)
        }
        const json = await response.json();
        if (!json.result) {
            console.log(`An error during json parse`)
            return Promise.reject(CONNECTION_ERROR_MESSAGE)
        }
        return json.result
    } catch (err) {
        console.log(err)
    }
}
