import updateOrganizations from "./updateOrganizations";
import updateLanguage from "./updateLanguage";
import updateUser from "./updateUser";

const reducer = (state, action) => {
    return {
        data: updateOrganizations(state, action),
        language: updateLanguage(state, action),
        user: updateUser(state, action)
    };
};

export default reducer;
