import React from "react";

const CheckBox = props => {

    const {onChange, value, label} = props;

    const onClickHandler = () => {
        if (onChange) onChange(!value)
    }

    const styleNameStr = value ? "checkbox-container checkbox-checked" : "checkbox-container"
    const styleNameSliderStr = value ? "checkbox-slider checkbox-slider-checked" : "checkbox-slider"

    return (
        <div className='d-flex-ac'>
            <div onClick={onClickHandler} className={styleNameStr}>
                <div className={styleNameSliderStr} />
            </div>
            {label && <p className='fs-12-10'>{ label }</p>}
        </div>
    )
}

export default CheckBox;
