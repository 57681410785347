import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer } from '@react-google-maps/api';

const containerStyle = {
    position: 'absolute',
    width: '100%',
    height: '100vh'
};

const ClusterMap = ({ data, onSelect, centerAddress, showPopup, hidePopup , centerObject, discounts }) => {

    const [mapZoom, setMapZoom] = useState(null)
    const [centerToGo, setCenterToGo] = useState(null)
    const [zoomToGo, setZoomToGo] = useState(7)
    //const [mapCenter, setMapCenter] = useState(null)

    useEffect(() => {
        if (map) {
            if (centerObject.lat && centerObject.lng) {
                setCenterToGo({lat: centerObject.lat, lng: centerObject.lng})
                setZoomToGo(15)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(centerObject)]);


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAoHM_q2q-uO_DZ-YOl3B-1mVBdhHyV57c"
    })


    const [map, setMap] = useState(null)

    const onZoomHandler = () => {
        setMapZoom(map?.zoom || null)
    }


    const onLoad = useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback() {
        setMap(null)
    }, [])

    let filteredData = data
    if (discounts) {
        filteredData = data.filter(el => (el.Discount || el.DiscountDescription))
    }

    const dataJSON = JSON.stringify(filteredData)

    const memoMap = useMemo(() =>
    {
        return (<div>
            {
                isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={centerToGo || centerAddress}
                        zoom={zoomToGo || mapZoom || 7}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onZoomChanged={onZoomHandler}
                        options={{
                            gestureHandling: "greedy",
                            zoom: mapZoom || 7,
                            maxZoom: 20,
                            minZoom: 3,
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false}}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        {<MarkerClusterer gridSize={100}>
                            {(clusterer) => {
                                const result = []
                                let index = 0;
                                for (let item of filteredData) {
                                    if (item.lat && item.lng) {
                                        result.push(<Marker key={`${result._id}-${index}`} icon={{
                                            url: item.icon,
                                            anchor: new window.google.maps.Point(14, 14),
                                            scaledSize: new window.google.maps.Size(28, 28)
                                        }}
                                                            position={item} noClustererRedraw
                                                            clusterer={clusterer}
                                                            onClick={() => onSelect(item)}
                                                            onMouseOver={(e) => showPopup(e, item)}
                                                            onMouseOut={() => hidePopup()}
                                                            clickable />)
                                    }
                                    index++
                                }
                                return result
                            }
                            }
                        </MarkerClusterer>}

                    </GoogleMap>
                ) : <></>
            }


        </div>)
    // eslint-disable-next-line
    }, [dataJSON, JSON.stringify(centerAddress), JSON.stringify(centerToGo)])

    return (
        <div>
            {memoMap}
        </div>
    );

}


export default React.memo(ClusterMap);
