import React, {useEffect, useState} from "react";
//import FormMap from "./form-map";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getOrganizations } from "../../actions/actions-organizations";
import Organization from "../organization";
import SearchPreview from "../search-preview";
import ClusterMap from "./cluster-map";
import useUrlQuery from "../../hooks/useUrlQuery";
import {useHistory} from "react-router-dom";
import {FiGift} from "react-icons/fi";

const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 1000 * 60
};

let currentName = ''

const Map = props => {

    const {organizations, filtered, currentCountry, getOrganizations, language, wording} = props

    const [browserGeo, setBrowserGeo] = useState(null)
    const history = useHistory()

    const [popupItem, setPopupItem] = useState(null)
    const [popupVisible, setPopupVisible] = useState(false)
    const [mouseCoordinates, setMouseCoordinates] = useState({x: 0, y: 0})
    const [discounts, setDiscounts] = useState(false)

    const {width, height} = useWindowDimensions();
    const urlQuery = useUrlQuery()
    const id = urlQuery.get('id')

    const showPopup = (e, item) => {

        if (item.Name === currentName) return;

        currentName = item.Name

        const {pageX, pageY} = e.domEvent;

        const finalX = (pageX + 150) < width ? pageX + 10 : pageX - 160;
        const finalY = (pageY + 250) < height ? pageY + 20 : -1;

        setMouseCoordinates({x: finalX, y: finalY});
        setPopupItem(item)
        setPopupVisible(true)
    }

    const hidePopup = () => {
        currentName = ''
        setPopupVisible(false)
    }

    useEffect(() => {

        getOrganizations();

        const { geolocation } = navigator;

        if (!geolocation) {
            console.log("Geolocation is not supported.");
            return;
        }

        geolocation.getCurrentPosition(
            position => {
                const {latitude, longitude} = position.coords;
                console.log(`Geolocation detected: ${latitude} ${longitude}`)
                setBrowserGeo({lat: latitude, lng: longitude})
            },
            error => console.log(error),
            geolocationOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (organizations?.length > 0 && id) {
            const existedItem = organizations.find(el => el.id === id)
            if (existedItem) {
                openPanel(existedItem)
                history.push('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, JSON.stringify(organizations)]);

    const [panelOpen, setPanelOpen] = useState(false);
    const [panelItem, setPanelItem] = useState(null);


    const openPanel = (item) => {
        setPanelItem(item);
        setPanelOpen(true)
    }

    const organizationsToDisplay = filtered?.length ? filtered : organizations;

    const centerPoint = (currentCountry && currentCountry.value === 'Беларусь') ? browserGeo || currentCountry : currentCountry;

    return (
        <div className="map">
            {/*
            <FormMap
                data={organizationsToDisplay}
                centerAddress={centerPoint}
                callback={openPanel}
            />*/}

            <ClusterMap discounts={discounts} centerObject={panelItem} data={organizationsToDisplay} onSelect={openPanel} centerAddress={centerPoint} showPopup={showPopup} hidePopup={hidePopup} />

            <SearchPreview top={width < 600 ? 140 : 80} onClick={openPanel} browserGeo={browserGeo} />

            <button onClick={() => setDiscounts(!discounts)} className={'bg-white p-absolute d-flex-ac'} style={{left: 15, bottom: 115, padding: '10px 16px', borderRadius: 10, gap: 6}}>
                <FiGift color={discounts ? '#FF0000' : '#3a3a3a'} />
                <p className='fs-12 ml-5' style={{color: discounts ? '#FF0000' : '#3a3a3a'}}>{discounts ? (wording['discounts-hide']?.[language] || 'Паказаць зніжкі') : (wording['discounts']?.[language] || 'Паказаць зніжкі')}</p>
            </button>

            {popupVisible &&
                <div className='w-150 bg-white shadow p-absolute' style={{
                    left: mouseCoordinates.x, ...(mouseCoordinates.y > -1 ? {top: mouseCoordinates.y} : {bottom: 10}),
                    borderRadius: 10
                }}>
                    {(popupItem?.Discount || popupItem?.DiscountDescription) && <div className={'p-relative'}>
                            <div className='bg-white w-30 h-30 p-absolute d-flex-center' style={{borderRadius: 15, top: -15, right: 10}}><FiGift /></div>
                    </div>}
                    {popupItem?.Image &&
                        <img className='mb-5' width={150} height={150} src={popupItem?.Image[0].url} alt='pin'
                             style={{objectFit: 'cover'}}/>}
                    {!popupItem?.Image &&
                        <div className='w-150 h-100 d-flex-center' style={{backgroundColor: '#df1212'}}><img
                            className='mb-5' width={50} height={50} src={popupItem?.icon} alt='pin'
                            style={{objectFit: 'cover'}}/></div>}
                    <div className='p-10'>
                        <p className='mb-5 fs-12'><strong>{popupItem.Name}</strong></p>
                        {popupItem.Services &&
                            <p className='fs-9'>{popupItem.Services.substring(0, 100) + (popupItem.Services.length > 100 ? '...' : '')}</p>}
                    </div>
                </div>}

            <SlidingPane
                isOpen={panelOpen}
                title={wording['card_details'][language]}
                width={width < 400 ? '100%' : 400}
                subtitle=""
                onRequestClose={() => {
                    setPanelOpen(false);
                }}>
                <div>
                    {panelItem && <Organization item={panelItem} />}
                </div>
            </SlidingPane>

        </div>
    );

}


const mapStateToProps = ({ data: { organizations, filtered, currentCountry }, language: {language, wording}}) => {
    return { organizations, filtered, currentCountry, language, wording };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizations: getOrganizations(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

