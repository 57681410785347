const {getDistance} = require('geolib')

export const searchString = (str, arr) => {
    let result = false;
    arr.forEach(el => {
        if (el && el.toString().toLowerCase().indexOf(str.toLowerCase()) > -1) {
            result = true;
            return result;
        }
    })
    return result;
}

export const searchArrays = (searchArr, originArr, fieldName) => {
    if (!searchArr || !originArr) return false
    let result = false;
    originArr.forEach(el => {
        searchArr.forEach(sa => {
            if (sa && sa.toString().toLowerCase().indexOf((fieldName ? el[fieldName] : el).toLowerCase()) > -1) {
                result = true;
                return result;
            }
        })
    })
    return result;
}

export const checkAllSelected = (arr, field) => {
    const filtered = arr.filter(el => el[field] === true)
    return arr.length === filtered.length;
}

export const checkAllDeSelected = (arr, field) => {
    const filtered = arr.filter(el => el[field] === false)
    return arr.length === filtered.length;
}

export const normalizeNumber = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export const geObjectToObjectDistance = (userLat, userLng, objLat, objLng) => {
    return getDistance({
        latitude: Number(userLat),
        longitude: Number(userLng),
    }, {
        latitude: Number(objLat),
        longitude: Number(objLng),
    })
}
