import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { filterOrganizations } from "../../../actions/actions-organizations";
import {AiOutlineClose} from "react-icons/ai";

const SearchInput = props => {

    const { countries, categories, filterOrganizations, noBorder, language, wording } = props;

    const [selectedCountry, setSelectedCountry] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedCategories, setSelectedCategories] = useState(categories);

    useEffect(() => {
        setSelectedCategories(categories)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    const updateTextHandler = (val) => {
        filterOrganizations(selectedCountry, val, selectedCategories);
        setSearchText(val)
    }

    const updateCountryHandler = (val) => {
        filterOrganizations(val, searchText, selectedCategories);
        setSelectedCountry(val)
    }

    const categorySelectHandler = (index) => {

        const categoriesCopy = [...categories]
        categoriesCopy[index].selected = !categoriesCopy[index].selected;

        filterOrganizations(selectedCountry, searchText, categoriesCopy);

        setSelectedCategories(categoriesCopy)
    }

    const isSelectedOne = categories.find(el => el.selected)

    return (
        <div>
            <div className={'input-search-container ' + (noBorder ? 'br-white shadow' : '')}>
                <select className='select-search fs-10 w-80 mr-10' value={selectedCountry} onChange={(e) => updateCountryHandler(e.target.value)}>
                    {countries.map((item, index) => <option key={index} value={item.value}>{item[language]}</option>)}
                </select>
                <input value={searchText} onChange={(e) => updateTextHandler(e.target.value)} placeholder={wording['search_field'][language]} className='input-search d-flex-1' />
                {searchText && searchText.length > 2 && <div className='ml-5'><AiOutlineClose className='enabledClick mt-5' onClick={() => updateTextHandler('')} color='#000' size={14} /></div>}
            </div>

            {
            <div className='p-fixed bg-white p-20 wp-100 shadow' style={{left: 0, bottom: 0, right:0, overflowX: 'scroll'}}>
                <div className='d-flex' style={{gap: 30}}>
                    {categories.map((item, index) =>
                        <div key={index} className='d-flex-ac enabledClick p-relative' style={{gap: 6, opacity: (item.selected || !isSelectedOne) ? 1 : .2}} onClick={() => categorySelectHandler(index)}>
                            <div className='d-flex-center p-relative'>
                                <img src={item.icon} className='w-25 h-25' alt='filter' />
                                <p className='fs-9 p-absolute bg-white' style={{top: 18, padding: '1px 3px', borderRadius: 4 }}>{item.num}</p>

                            </div>
                            <p className='fs-12' style={{maxWidth: 90}}>{item[language]}</p>
                        </div>)}
                </div>
            </div>
            }
        </div>

    )
}
const mapStateToProps = ({ data: { countries, categories }, language: { language, wording }}) => {
    return { countries, categories, language, wording };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterOrganizations: filterOrganizations(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
